@import url('https://fonts.googleapis.com/css2?family=Open+Sans&family=Roboto&family=Roboto+Slab&display=swap');

.parent-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.parent-container img{
  justify-content: center;
  align-items: center;
  text-align: center;
}

.character-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 1rem;
}

@media only screen and (max-width: 600px) {
  .character-row {
    flex-direction: column;
  }
}

.character-card {
  display: inline-flex;
  margin: 1rem;
  border-radius: 1rem;
  width: 250px;
  height: 250px;
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  overflow: hidden;
  position: relative;
}

@media only screen and (max-width: 600px) {
  .character-card {
    width: 90%;
    height: auto;
  }
}

.character-card img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 1rem;
  display: block;
}

.character-card:hover img {
  filter: brightness(50%);
}

.character-card:hover .overlay {
  opacity: 1;
}

.character-card .overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 1rem;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
  pointer-events: none; /* add this line */
}

.character-card:hover {
  transform: scale(1.05);
}

.character-card .name {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  margin: 0.5rem 0;
  padding: 0.5rem;
  color: #fff;
  font-size: 1.2rem;
  text-align: center;
  font-family: 'Roboto Slab', serif;
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
}

.character-card:hover .name {
  opacity: 1;
}